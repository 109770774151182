
  
  /* Main Container */
  .cash-in-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e9f2fa;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f3f4f6;
    color: #333;
    background-image: url('https://www.thedigitaltransformationpeople.com/wp-content/uploads/2018/03/grid-3227459_1920.jpg');
    background-size: cover;
    background-position: center;
  }
  .cash-in-page-2 {
    height: 80vh;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 30px;
    background: #ffffff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f2f2f2;
    color: #333;
    border-radius: 10px;
    border:1px solid #c84347
  }
  /* Form Container */
  .form-container-cashing {
    /* background: #fff; */
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    width: 50%;
    max-width: 500px;
    background-color: #f2f2f2;

  }
  
  .form-groups {
    margin-bottom: 20px;
  }
  
  .form-groups label {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 8px;
  }
  
  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Input & Select */
  .inputs-amount, select, textarea {
    width: 98%;
    padding: 8px;
    font-size: 16px;
    border: 0.8px solid #c84347;
    /* border-radius: 6px; */
    transition: all 0.3s ease;
    margin-top: 5px;
    background-color: transparent;
    color: #474747;
  }
  .inputs-amount::placeholder{
    color: #ebebeb;
  }
  

  
  /* Currency Select */
  .currency-select {
    margin-top: 3px;
    width: 120px;
    font-size: 14px;
    background-color: transparent;
  }
  .amount-container{
    display: flex;

  }
  .currency-select{
    width: 150px;
  }
  /* Textarea */
  textarea {
    height: 80px;
    resize: none;
  }
  
  /* Keypad Container */
  .keypad-container {
    width: 50%;
    display: flex;
    justify-content: center;
    
  }
  
  .keypad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    max-width: 400px;
    width: 100%;
    background-color: #f2f2f2;
    padding: 38px;
    border-radius: 8px;

  }
  
  .keypad-button {
    padding: 20px;
    font-size: 18px;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    background-color: #f2f2f2;
    color: #333;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c84347;
  }
  
  .keypad-button:hover {
    background-color: #f4f4f4;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .keypad-button:active {
    background-color: #e1e1e1;
    box-shadow: none;
  }
  
  /* Specific Buttons Styles */
  .keypad-button.backspace {
    background-color: #f56262;
    color: white;
    font-weight: bold;
  }
  
  .keypad-button.confirm {
    background-color: #4caf50;
    color: white;
    font-weight: bold;
  }
  
  .keypad-button.confirm:hover {
    background-color: #45a049;
  }
  
  /* Mobile Responsiveness */
  @media(max-width: 768px) {
    .cash-in-page {
      padding: 20px;
    }
  
    .form-container-cashing {
      width: 100%;
      padding: 20px;
    }
  
    .keypad {
      grid-template-columns: repeat(3, 1fr);
      gap: 12px;
    }
  
    .keypad-button {
      padding: 18px;
      font-size: 16px;
    }
  
    .currency-select {
      width: 100%;
      margin-top: 10px;
    }
  
    .form-group input,
    .form-group textarea,
    .form-group select {
      font-size: 14px;
    }
  }
  