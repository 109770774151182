/* Container styling */
.form-container {
    width: 90vw;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .form-container2 {
    width: 90vw;
    /* margin: 50px auto; */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .employee-form{
    max-width: 600px;
    height: 70vh;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
  }
  /* Webkit Scrollbar Styling */
.employee-form::-webkit-scrollbar {
    width: 3px;
    height: 3px; /* Adjust scrollbar height */
  }
  
  .employee-form::-webkit-scrollbar-thumb {
    background-color: #c84347; /* Thumb color */
    border-radius: 5px; /* Rounded corners for the thumb */
  }
  
  .employee-form::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Track color */
  }
  /* Title styling */
  .form-title {
    text-align: center;
    color: #c42027;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Form styling */
  .employee-form {
    display: flex;
    flex-direction: column;
  }
  
  /* Input fields styling */
  .employee-form input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .select-data{
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  /* Submit button styling */
  .submit-button {
    background-color: #c42027;
    color: #fff;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #a3181f;
  }
  /* Main container */
.employee-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Title styling */
  .title {
    text-align: center;
    color: #c42027;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Employee list */
  .employee-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  /* Employee card */
  .employee-card {
    background-color: #eeeeee;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    width: 200px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  .employee-daba{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }
  .employee-card:hover {
    transform: scale(1.05);
  }
  
  /* Employee details modal */
  .employee-details {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .employee-details h2 {
    color: #c42027;
    margin-bottom: 15px;
  }
  
  .employee-details p {
    margin: 5px 0;
  }
  
  .close-button {
    background-color: #c42027;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .close-button:hover {
    background-color: #a3181f;
  }
/* Styles for the report component */
.bestEverContainer {
    padding: 20px;
    background-color: #fff;
}

.salesReportTitle {
    color: #c84347;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
}

.filtersSection {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filterInput {
    margin: 0 10px;
    padding: 8px;
    border: 1px solid #c84347;
    border-radius: 5px;
}

.filterSelect {
    margin-left: 10px;
    padding: 8px;
    border: 1px solid #c84347;
    border-radius: 5px;
}

.ordersTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid #c84347;
}

    .ordersTable th, .ordersTable td {
        padding: 10px;
        text-align: left;
        border: 1px solid #c84347;
        color:black
    }

.totalSales {
    font-size: 22px;
    color: #c84347;
    margin-top: 20px;
    text-align: center;
}

.downloadButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #c84347;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}

    .downloadButton:hover {
        background-color: #9e3239;
    }
