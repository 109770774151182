/* TableArea.css */
.h-table-area-wrapper {
  display: flex;
  height: 100vh;
  background-color: #ffffff;
}

.h-sidebar-container {
  width: 260px;
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
}

.h-table-list-container {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.h-table-item-container {
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
}

.h-table-item-container:hover {
  background-color: #f5f5f5;
}

.h-table-item-container.active {
  background-color: #c84347;
  color: #ffffff;
}

.h-add-table-btn {
  margin: 1rem;
  padding: 0.75rem;
  background-color: #c84347;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.h-add-table-btn:hover {
  background-color: #9b3a3e;
}

.h-main-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.h-form-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.h-form-container {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.h-form-container h3 {
  margin-bottom: 1.5rem;
  color: #c84347;
  font-size: 1.25rem;
}

.h-form-container input,
.h-form-container select {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
}

.h-form-container input:focus,
.h-form-container select:focus {
  border-color: #c84347;
  outline: none;
}

.h-form-actions-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.h-cancel-btn,
.h-save-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.h-cancel-btn {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
}

.h-cancel-btn:hover {
  background-color: #f5f5f5;
}

.h-save-btn {
  background-color: #c84347;
  color: #ffffff;
  border: none;
}

.h-save-btn:hover {
  background-color: #9b3a3e;
}

.h-navbar-container {
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  padding: 1rem 2rem;
}

.h-navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h-navbar h2 {
  font-size: 1.5rem;
  color: #c84347;
  margin: 0;
}

.h-table-info-container {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  color: #666666;
}

.h-status-indicator {
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
  font-size: 0.875rem;
  background-color: #f5f5f5;
  color: #c84347;
}

.h-status-indicator.active {
  border: 1px solid #c84347;
}

.h-status-indicator.inactive {
  border: 1px solid #666666;
  color: #666666;
}

.h-status-indicator.draft {
  border: 1px solid #999999;
  color: #999999;
}

.h-sub-navbar-container {
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 2rem;
  display: flex;
  gap: 1rem;
}

.h-sub-nav-tab {
  padding: 1rem 1.5rem;
  cursor: pointer;
  color: #666666;
  font-weight: 500;
  transition: all 0.2s ease;
  border-bottom: 2px solid transparent;
}

.h-sub-nav-tab:hover {
  color: #c84347;
}

.h-sub-nav-tab.active {
  color: #c84347;
  border-bottom: 2px solid #c84347;
}

.h-page-content-container {
  padding: 1rem;
  background-color: #ffffff;
}
