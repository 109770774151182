/* Details Page Container */
.h-details-page {
  padding: 20px;
  background-color: #fff;
}

/* Sections */
.h-top-section,
.h-bottom-section {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.h-general-data,
.h-table-service,
.h-amount,
.h-administrative-data {
  padding: 15px;
  border: 2px solid #c84347;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Left Sections */
.h-general-data,
.h-amount {
  flex: 2;
}

/* Right Sections */
.h-table-service,
.h-administrative-data {
  flex: 1;
}

/* Headings */
/*h2 {
  color: #c84347;
  margin: 0 0 15px 0;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid #c84347;
  padding-bottom: 5px;
}*/

/* Lists */
/*ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {*/
  /* padding: 8px 0; */
  /* border-bottom: 1px solid #eee; */
  /*display: flex;*/
  /* justify-content: space-between; */
  /* align-items: center; */
/*}

li:last-child {
  border-bottom: none;
}*/

/* Checkboxes */
/*input[type="checkbox"] {
  margin-left: 10px;
  transform: scale(1.2);
  accent-color: #c84347;*/ /* Sets checkbox accent to #c84347 */
/*}*/

/* Buttons (Optional) */
/*button {
  background-color: #c84347;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #a33235;
}*/

/* Responsive Layout */
/*@media (max-width: 768px) {
  .h-top-section,
  .h-bottom-section {
    flex-direction: column;
  }
}*/

/* Subtle Shadows for Cards */
.h-general-data,
.h-table-service,
.h-amount,
.h-administrative-data {
  transition: box-shadow 0.3s ease;
}

.h-general-data:hover,
.h-table-service:hover,
.h-amount:hover,
.h-administrative-data:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
