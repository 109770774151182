.currencies-details-container {
    padding: 1.5rem;
    background-color: #ffffff;
  }
  
  .section {
    margin-bottom: 2rem;
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .section h2 {
    font-size: 1.25rem;
    color: #c84347;
    margin-bottom: 1rem;
  }
  
  .field {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    font-size: 1rem;
    color: #333333;
  }
  
  .field label {
    font-weight: bold;
    color: #666666;
  }
  