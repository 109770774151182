/* DrawersAccount.css */

.drawers-accounts-page {
    padding: 2rem;
    background-color: #ffffff;
    color: #333;
    font-family: Arial, sans-serif;
  }
  
  /* Search Bar */
  .search-bar-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  
  .search-input {
    width: 100%;
    max-width: 400px;
    padding: 0.6rem 1rem;
    font-size: 1rem;
    border: 2px solid #c84347;
    border-radius: 30px; /* Rounded for a polished look */
    color: #333;
    background-color: #ffffff;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .search-input:focus {
    outline: none;
    border-color: #b03539;
    box-shadow: 0px 4px 8px rgba(200, 67, 71, 0.4);
  }
  
  .search-input::placeholder {
    color: #c84347; /* Matches theme */
    font-style: italic;
  }
  
  /* Table Container */
  .accounts-table-container {
    overflow-x: auto;
    border: 2px solid #c84347;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Table */
  .accounts-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .accounts-table th,
  .accounts-table td {
    padding: 0.8rem 1rem;
    border: 1px solid #c84347;
  }
  
  .accounts-table th {
    background-color: #c84347;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .accounts-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .accounts-table tr:hover {
    background-color: #ffe5e5;
  }
  
  .accounts-table td {
    color: #333;
    font-size: 0.9rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .search-bar-container {
      justify-content: center;
    }
  
    .search-input {
      width: 100%;
      max-width: none;
    }
  }
  