.details-page {
    max-width: 1200px;
    
    height: 500px;
    font-family: Arial, sans-serif;
    overflow-y: auto;
}

.section {
    background: #ffffff;
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
}

h2 {
    color:   #000000;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #000;
    font-size: 1.3rem;
    font-weight: bold;
}

label {
    display: block;
    margin: 1rem 0 0.5rem;
    color: #404040;
    font-size: 0.9rem;
}

input[type="text"] {
    width: 100%;
    padding: 0.6rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    background: #fff;
}

input[type="text"]:focus {
    border-color: #000;
    outline: 2px solid rgba(0, 0, 0, 0.1);
}

input[type="checkbox"] {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
    border: 1px solid #000;
}

input[readonly] {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

label[for="sap-checkbox"] {
    display: flex;
    align-items: center;
    margin: 1.5rem 0 0;
}

@media (min-width: 768px) {
    .details-page {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
}

input::placeholder {
    color: #999;
    opacity: 0.8;
}