/* Currencies.css */
.currencies-container {
    display: flex;
    height: 100vh;
    background-color: #ffffff;
}

.currencies-sidebar {
    width: 260px;
    background-color: #ffffff;
    border-right: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
}

.currencies-list-container {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
}

.currencies-item-container {
    padding: 0.75rem 1rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
}

.currencies-item-container:hover {
    background-color: #f5f5f5;
}

.currencies-item-container.active {
    background-color: #c84347;
    color: #ffffff;
}

.add-currencies-btn {
    margin: 1rem;
    padding: 0.75rem;
    background-color: #c84347;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.add-currencies-btn:hover {
    background-color: #9b3a3e;
}

.currencies-main-content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.currencies-form-overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.currencies-form-container {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.currencies-form-container h3 {
    margin-bottom: 1.5rem;
    color: #c84347;
    font-size: 1.25rem;
}

.currencies-form-container input,
.currencies-form-container select {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 1rem;
}

.currencies-form-container input:focus,
.currencies-form-container select:focus {
    border-color: #c84347;
    outline: none;
}

.currencies-form-actions-container {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;
}

.currencies-cancel-btn,
.currencies-save-btn {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
}

.currencies-cancel-btn {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
}

.currencies-cancel-btn:hover {
    background-color: #f5f5f5;
}

.currencies-save-btn {
    background-color: #c84347;
    color: #ffffff;
    border: none;
}

.currencies-save-btn:hover {
    background-color: #9b3a3e;
}

.currencies-navbar-container {
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    padding: 1rem 2rem;
}

.currencies-navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.currencies-navbar h2 {
    font-size: 1.5rem;
    color: #c84347;
    margin: 0;
}

.currencies-info-container {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    color: #666666;
}

.currencies-status-indicator {
    padding: 0.25rem 0.75rem;
    border-radius: 999px;
    font-size: 0.875rem;
    background-color: #f5f5f5;
    color: #c84347;
}

.currencies-sub-navbar-container {
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    padding: 0 2rem;
    display: flex;
    gap: 1rem;
}

.currencies-sub-nav-tab {
    padding: 1rem 1.5rem;
    cursor: pointer;
    color: #666666;
    font-weight: 500;
    transition: all 0.2s ease;
    border-bottom: 2px solid transparent;
}

.currencies-sub-nav-tab:hover {
    color: #c84347;
}

.currencies-sub-nav-tab.active {
    color: #c84347;
    border-bottom: 2px solid #c84347;
}

.currencies-page-content-container {
    padding: 1rem;
    background-color: #ffffff;
}
