.top-bar{
    display:flex;
}
.time-recording-card {
    width: 95%;
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    background-color: #fff;
}
.time-recording-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}
.message {
    text-align: center;
}
.message-text {
    font-size: 1.125rem;
    font-weight: 500;
}
.confirmation-table {
    width: 100%;
    background-color: #f3f4f6;
    padding: 0.5rem;
    border-radius: 0.375rem;
}
.table-header {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}
.confirmation-table-main {
    width: 100%;
    font-size: 0.875rem;
}
.table-cell {
    padding: 0.25rem 0;
}
.start-again-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}
.start-again-button {
    padding: 0.5rem 1.5rem;
    border: 1px solid black; 
    background-color: transparent;
    color: #4b5563;
    border-radius: 0.375rem;
    cursor: pointer;
}
.start-again-button:hover {
        background-color: #f3f4f6;
    }
.payment-popup-content-items-2 {
    max-height: 80vh;
    width: 50vw;
    background-color: white;
    border: 3px solid #c84347;
    border-radius: 10px;
    animation: animate2 0.5s ease-in;
    overflow: hidden;
    padding: 10px
}



.cashflow-container {
    padding: 1rem;
    max-width: 1200px;
    margin: 0 auto;
}
.cashflow-section {
    margin-bottom: 2rem;
}
.cashflow-section-title {
    font-weight: bold;
    margin-bottom: 1rem;
}
.cashflow-table-container {
    overflow-x: auto;
}
.cashflow-table {
    width: 100%;
    border-collapse: collapse;
}
.cashflow-table-header {
    background-color: #f3f4f6;
}
.cashflow-table-header-cell {
    padding: 0.5rem 1rem;
    text-align: left;
    font-size: 0.875rem;
}
.cashflow-table-row {
    border-top: 1px solid #ddd;
}
.cashflow-table-cell {
    padding: 0.5rem 1rem;
    text-align: left;
    font-size: 0.875rem;
}
.cashflow-input-container {
    margin-top: 1rem;
}
.cashflow-input-label {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
}
.cashflow-input-field {
    width: 8rem;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 0.375rem;
}
.cashflow-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    width:80%;
    margin:auto
}
.cashflow-next-button,
.cashflow-cancel-button {
    padding: 0.75rem 1.5rem;
    border: 1px solid #ddd;
    border-radius: 0.375rem;
    background-color: #f3f4f6;
    color: #333;
}
.cashflow-cancel-button {
    color: #e11d48;
}
.payment-popup-content-items-3 {
    max-height: 80vh;
    width: 95vw;
    background-color: white;
    border: 3px solid #c84347;
    border-radius: 10px;
    animation: animate2 0.5s ease-in;
    overflow: hidden;
    padding: 10px;
    overflow-y:scroll;
    z-index:999;
}






.payment-interface {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #fff;
    border-radius: 8px;
}
.payment-section {
    display: flex;
    gap: 32px;
}
.payment-card {
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 300px;
}
.payment-title {
    font-size: 18px;
    color: #007bff;
    margin-bottom: 12px;
    font-weight: 500;
}
.payment-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.currency-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}
.currency-label {
    width: 80px;
    font-size: 14px;
}
.currency-input,
.currency-result {
    width: 80px;
    text-align: right;
    padding: 4px;
    font-size: 14px;
}
.currency-input-selected {
    border: 2px solid #007bff;
}
.currency-equal {
    margin: 0 8px;
}
.payment-total {
    border-top: 1px solid #ddd;
    text-align: right;
    padding-top: 8px;
}
.payment-table {
    width: 100%;
}
.payment-method,
.payment-value {
    padding: 8px;
    font-size: 14px;
    text-align: left;
}
.payment-input {
    width: 80px;
    text-align: right;
    padding: 4px;
}
.payment-input-selected {
    border: 2px solid #007bff;
}
.number-pad-container {
    margin-top: 16px;
}
.number-pad {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
}
.number-pad-button {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    background-color: #f7f7f7;
    cursor: pointer;
}
.number-pad-button:hover {
        background-color: #e2e2e2;
    }
.number-pad-button:active {
        background-color: #d1d1d1;
    }






.payment-summary-container {
    padding: 16px;
    max-width: 1200px;
    margin: 0 auto;
}
.summary-tables-row {
    display: flex;
    gap: 16px;
}
.summary-table-card {
    flex: 1; /* Makes the cards take up equal space */
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
}
.summary-table-content {
    padding: 16px;
}
.summary-table-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #007bff;
}
.summary-table {
    width: 100%;
    border-collapse: collapse;
}
.summary-table-header-row {
    border-bottom: 2px solid #ddd;
}
.summary-table-header {
    text-align: right;
    padding: 8px;
    background-color: #f9f9f9;
    font-weight: bold;
}
.summary-table-row {
    border-bottom: 1px solid #ddd;
}
.summary-table-cell {
    padding: 8px;
    text-align: right;
}
.method-cell {
    text-align: left;
    white-space: pre-line;
}
.summary-table-row:last-child {
    border-bottom: none;
}
