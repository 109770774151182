/* Table Container */
.table-container {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  

  
  /* Table Styles */
  .org-unit-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    text-align: left;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .org-unit-table th,
  .org-unit-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .org-unit-table th {
    background-color: #333;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .org-unit-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .org-unit-table tr:hover {
    background-color: #e9e9e9;
    transition: background-color 0.3s ease-in-out;
  }
  
  /* Centering Text in Default Column */
  .org-unit-table td:last-child {
    text-align: center;
  }
  