.time-recording-container {
    font-family: Arial, sans-serif;
    padding: 20px;
}
.time-recording-table {
    width: 100%;
    border-collapse: collapse;
}
.time-recording-table th,
.time-recording-table td {
        border: 1px solid #c84347;
        padding: 8px;
        text-align: left;
    }
.time-recording-table th {
        background-color: #f4f4f4;
        font-weight: bold;
    }
.time-recording-table tr:nth-child(even) {
        background-color: #f9f9f9;
    }
.time-recording-table tr:hover {
        background-color: #f1f1f1;
    }
.status-column {
    width: 50px;
}
.punch-in-column,
.punch-out-column,
.hours-column {
    width: 80px;
}
.punch-in-pos-column,
.punch-out-pos-column {
    width: 150px;
}
.user-name-column {
    width: 120px;
}
.punch-in-supervisor-column,
.punch-out-supervisor-column {
    width: 140px;
}
.monthly-previewed-out-column {
    width: 130px;
}
.search-bar-timerecord{
    height:30px;
    width:30%;
    margin-bottom:20px

}
.search-bar-timerecord::placeholder {
    color:black
}