.container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .navbar h2 {
    color: #C84347;
    margin: 0;
  }
  
  .controls {
    display: flex;
    gap: 10px;
  }
  
  .controls input[type="text"] {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    min-width: 200px;
  }
  
/*  button {
    background-color: #C84347;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }*/
  
/*  button:hover {
    background-color: #a63538;
  }*/
  
/*  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f8f8f8;
    color: #C84347;
    font-weight: 600;
  }
  
  tr:hover {
    background-color: #fff5f5;
  }
  
  td button {
    margin-right: 5px;
    font-size: 0.9em;
    padding: 6px 12px;*/
/*  }*/
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content h3 {
    color: #C84347;
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 16px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    color: #333;
  }
  
  .form-group input[type="text"] {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-group input[type="checkbox"] {
    margin-right: 8px;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 24px;
  }
  
  .form-actions button[type="button"] {
    background-color: #6c757d;
  }
  
  .form-actions button[type="button"]:hover {
    background-color: #5a6268;
  }
  
  input[type="text"]:focus {
    outline: none;
    border-color: #C84347;
    box-shadow: 0 0 0 2px rgba(200, 67, 71, 0.2);
  }