
.checkout-container {
    width: 93%;
    margin: 2rem auto;
    background-color: #fff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.checkout-top-nav {
    display: flex;
    margin-bottom: 1rem;
}
.top-nav-item {
    color: black;
    border: none;
    padding: 0.8rem 1.2rem;
    margin-right: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
    .top-nav-item:hover {
        color: white;
        background-color: #c84347;
    }
.top-nav-item.active-main-tab {
        background-color: #c84347;
        font-weight: bold;
        color: white
    }
.sub-navigation {
    display: flex;
    margin-top: 1.5rem;
}
.sub-nav-item {
    background-color: #e9e9e9;
    color: #333;
    border: 1px solid #ddd;
    padding: 0.3rem 1rem;
    margin-right: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.sub-nav-item:hover {
        background-color: #d1d1d1;
    }
.sub-nav-item.active-sub-tab {
        border-bottom:3px solid #c84347;
        color: black;
        font-weight: bold;
    }
.content-area {
    margin-top: 2rem;
}
.content-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
}
.pos-system-content,
.accounting-content,
.currency-content,
.default-content {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 2rem;
    margin-top: 1.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.pos-system-content p,
.accounting-content p,
.currency-content p,
.default-content p {
        font-size: 1.1rem;
        color: #666;
    }
.checkout-bottom-bar {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}
.bottom-bar-action-btn {
    background-color: #c84347;
    color: white;
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    transition: background-color 0.3s ease;
}
.bottom-bar-action-btn:hover {
        background-color: #a93b3d;
    }
.icon {
    width: 16px;
    height: 16px;
    margin-right: 0.5rem;
}
.edit-btn {
    background-color: #4caf50;
}
.edit-btn:hover {
        background-color: #45a049;
    }




.sap-settings-form-container {
    padding: 16px;
    max-width: 800px;
    margin: 0 auto;
}
.sap-settings-form-section {
    margin-bottom: 16px;
}
.sap-settings-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.sap-settings-form-label {
    width: 200px;
    margin-right: 16px;
    font-size: 14px;
    color: #4B5563;
}
.sap-settings-form-required {
    color: #EF4444;
}
.sap-settings-form-input {
    border: 1px solid #D1D5DB;
    padding: 8px;
    width: 256px;
    font-size: 14px;
}
.sap-settings-form-select-wrapper {
    position: relative;
    width: 256px;
}
.sap-settings-form-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #D1D5DB;
    font-size: 14px;
    background-color: white;
    color: #6B7280;
    appearance: none;
}
.sap-settings-form-checkbox {
    margin-left: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}



.payment-methods-table-container {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    background-color: #f9fafb;
    border-radius: 8px;
}
.payment-methods-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.payment-methods-table-header {
    background-color: #c84347;
    color: white;
    text-align: left;
    padding: 10px;
}
.payment-methods-table-column {
    padding: 12px;
    font-size: 14px;
    font-weight: normal;
    border-bottom: 1px solid #E5E7EB;
}
.payment-methods-table-row-even {
    background-color: #f9fafb;
}
.payment-methods-table-row-odd {
    background-color: #ffffff;
}
.payment-methods-table-cell {
    padding: 12px;
    vertical-align: middle;
    font-size: 14px;
    color: #4B5563;
}
.payment-methods-table-input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    background-color: white;
    color: #4B5563;
    box-sizing: border-box;
}
.payment-methods-table-input:focus {
        border-color: #60A5FA;
        outline: none;
    }
.payment-methods-table-checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.payment-methods-table-logo {
    height: 30px;
    object-fit: contain;
    border-radius: 4px;
}
.payment-methods-table-cell img {
    display: block;
    margin: 0 auto;
}




.unique-tax-table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.unique-tax-table {
    width: 90%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    border: 1px solid #c84347
}
.unique-tax-table th, .unique-tax-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
    border: 1px solid #c84347;
    padding:12px

    }
.unique-tax-table th {
        background-color: #c84347;
        font-weight: bold;
        color:white
    }
.unique-input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    background-color: white;
    color: #4B5563;
    box-sizing: border-box;
}
.unique-select {
    width: 100%;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: white;
}
.unique-checkbox {
    transform: scale(1.5);
    cursor: pointer;
}



.unique-currency-table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}
.unique-currency-table {
    width: 80%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}
.unique-currency-table th, .unique-currency-table td {
        border: 1px solid #c84347;
        padding: 8px;
        text-align: center;
    }
.unique-currency-table th {
        background-color: #c84347;
        font-weight: bold;
        color:white
    }
.unique-input {
    width: 100%;

}
.unique-select {
    width: 100%;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: white;
}
.unique-checkbox {
    transform: scale(1.5);
    cursor: pointer;
}
.unique-footer-text {
    margin-top: 10px;
    font-weight: bold;
}




