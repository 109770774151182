.receipt-container {
    display: flex;
    width: 100%;
    height: 100vh;
    font-family: Arial, sans-serif;
}
.receipt-sidebar {
    width: 250px;
    background-color: #f4f4f4;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.receipt-tab {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    text-align: left;
    transition: 0.3s;
}
.receipt-active-tab {
    font-weight: bold;
    color: white;
    background-color: #c84347;
    padding: 10px;
    border:none;
}
.receipt-content {
    flex-grow: 1;
    padding: 20px;
}
.receipt-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}
.receipt-grid-item {
    background-color: #e0e0e0;
    border: none;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
}
.receipt-grid-item:hover {
        background-color: #d0d0d0;
    }
.uniques-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.uniques-popup-content {
    height:83vh;
    width:80vw;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}
.main-tax-rates {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    gap:20px
}
.tax-rates {
    padding: 10px;
    font-size:30px;
}
.tax-rates:hover {
        background-color: #ffd1d1;
        width: 100%
    }


.cancel-recipt{
    height:80%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    gap:20px
}
.cancel-recipt-text{
    font-size:30px
}
.cancel-recipt-button {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid red;
    color: red
}