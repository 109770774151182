.currencies-roundings-container {
    padding: 20px;
    background-color: #ffffff;
  }
  
  .currencies-roundings-container h2 {
    color: #c84347;
    margin-bottom: 1rem;
  }
  
  .currencies-roundings-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
  }
  
  .currencies-roundings-table th,
  .currencies-roundings-table td {
    padding: 10px;
    border: 1px solid #e0e0e0;
    text-align: left;
  }
  
  .currencies-roundings-table th {
    background-color: #f5f5f5;
    color: #c84347;
    font-weight: bold;
  }
  
  .currencies-roundings-table tbody tr:hover {
    background-color: #f9f9f9;
  }
  
  .currencies-roundings-table td {
    color: #666666;
  }
  