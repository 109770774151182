/* Receipt Component Styles */

.h-receipt-container {
    font-family: Arial, sans-serif;
    color: #c84347;
    background-color: #fff;
    padding: 20px;
  }
  
  /* Navbar */
  .h-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #c84347 !important;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
  }
  
  .h-navbar h1 {
    margin: 0;
    font-size: 18px;
  }
  .h-search-bar-container{
    padding-right: 15px;
  }
  .h-search-bar {
    padding: 5px 10px;
    border: 2px solid #c84347;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  /* Table */
  .h-table-container {
    margin-top: 20px;
    overflow-x: auto;
  }
  
  .h-receipt-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #000;
  }
  
  .h-receipt-table th,
  .h-receipt-table td {
    border: 1px solid #ddd;
  color: #333;
    padding: 8px 10px;
    text-align: center;
    text-wrap: nowrap;
  }
  
  .h-receipt-table th {
    background-color: #c84347;
    color: #fff;
    font-size: 14px;
  }
  
  .h-receipt-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .h-receipt-table tr:hover {
    background-color: #eaeaea;
  }
  
  .h-no-data {
    text-align: center;
    color: #888;
    font-style: italic;
  }
  