.h-signature-container {
  padding: 2rem;
  background-color: #c84347; /* Use the theme color for the background */
  
}

.h-signature-container h2 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff; /* White text for contrast against the theme color */
}
