.h-coupons-container {
  padding: 2rem;
  max-width: 100%;
  overflow-x: auto;
  background-color: #fff; /* White background for the container */
}

/* Table Styles */
.h-coupons-table {
  width: 100%;
  border-collapse: collapse;
  
}

.h-coupons-table th {
  background-color: #c84347; /* Theme color for table headers */
  color: #fff; /* White text for better readability */
  font-weight: 500;
  text-align: left;
  padding: 12px 15px;
  border: 2px solid #c84347; /* Bold border for headers */
}

.h-coupons-table td {
  padding: 10px 15px;
  border: 1px solid #ddd;
  color: #333; /* Standard text color */
}

.h-coupons-table tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Light gray for alternating rows */
}

.h-coupons-table tbody tr:hover {
  background-color: #f1f1f1; /* Subtle hover effect */
  border: 1px solid #c84347; /* Highlight border with theme color */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .h-coupons-container {
    padding: 1rem;
  }
  
  .h-coupons-table th,
  .h-coupons-table td {
    padding: 8px 10px;
    font-size: 0.9rem; /* Adjusted font size for smaller screens */
  }
}
