/* Overall container styles */
.h-receipt-details-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Main Navbar */
.h-navbar {
  background-color: #c84347;
  color: #fff;
  padding: 15px 20px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
}

/* Header with Receipt ID */
.h-receipt-header {
  background-color: #fff;
  border: 2px solid #c84347;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
}

.h-receipt-header h2 {
  margin: 0;
  font-size: 1.8rem;
  color: #c84347;
}

.h-receipt-id {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #c84347;
}

.h-receipt-status {
  font-size: 1.2rem;
  color: #555;
  margin-top: 5px;
}

/* Sub-navbar styles */
.h-sub-navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #c84347;
  color: #fff;
  border-radius: 8px;
  padding: 10px;
  overflow-x: auto !important;
  scrollbar-width: thin;
  scrollbar-color: #c84347 #fff;
  cursor: pointer;
}

/* .h-sub-nav-tab {
  color: #fff;

  font-size: 1rem;
  font-weight: 500;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 4px;
} */

.sub-nav-tab {
  text-wrap: nowrap !important;
}
.h-sub-nav-tab:hover {
  background-color: #fff;
  color: #c84347;
  border: 1px solid #c84347;
}

.active {
  background-color: #fff;
  color: #c84347;
  padding: 15px;
  border: 2px solid #c84347;
  font-weight: bold;
  /* border-radius: 50px; */
}

/* Content area styles */
.h-content {
  background-color: #fff;
  border: 2px solid #c84347;
  border-radius: 8px;
  padding: 20px;
  font-size: 1rem;
  color: #333;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .h-sub-navbar {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .h-sub-nav-tab {
    width: 100%;
    text-align: center;
  }

  .h-receipt-header h2 {
    font-size: 1.5rem;
  }

  .h-receipt-id {
    font-size: 1rem;
  }

  .h-navbar {
    font-size: 1.2rem;
  }
}
