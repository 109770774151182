

/* -----------------------------------Mian----------------------------------------- */

.main-terminal{
    display:flex;
}

/* -----------------------------------Toolbar----------------------------------------- */

.toolbar-header {
    display: flex;
    justify-content: space-between;
    background-color: #c84347;
}
.img-section{
    display: flex;
    align-items: center;
    padding: 10px;
}
.terminal-img{
    width: 80px;
    height:60px
}
.text-section-1{
    font-size: 12px;
    color:white;
}
.text-section{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 30px;
}
.text-section-2{
    font-size: 18px;
    color:white;
    border-right: 1px solid white;
    padding-right: 20px;
    cursor: pointer;
}
.funtion-popup{
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.466);
}
.funtion-popup-content{
    height: fit-content;
    width: 50vh;
    background-color: white;
    border: 3px solid #c84347;
    border-radius: 10px;
    animation: animate 0.2s ease-in;
    overflow: hidden;
    padding-bottom:50px
}
@keyframes animate {
    0%{
     margin-top: 300px;
     opacity: 0;
    }
    100%{
     margin-top: 0px;
     opacity: 1;
    }
}
.close-popup{
    width:100%;
    text-align: end;
    font-size: 28px;
    color: red;
    background-color: transparent;
    border: none;
    padding: 0%;

}
@media(max-width:768px){
    .toolbar-header{
     display: flex;
     flex-direction: column;
    }
    .funtion-popup-content{
     height: 80vw;
     width: 90vh;
     background-color: white;
    }
}
.functions-main {
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.funtions-option {
    font-size: 25px;
    cursor: pointer
}
/* -----------------------------------Items----------------------------------------- */

.Toolbar-Items {
    width: 35vw;
    height: 85vh;
    padding: 5px;
    border: 1px solid red;
    margin: 5px;
    overflow-y: scroll; 
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}
.Toolbar-Items::-webkit-scrollbar {
    display: none; 
 }
.item-list-terminal{
    list-style-type: none;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    height: 63vh;
    overflow-y: scroll;
    scrollbar-width: none;
    width:100%;
    justify-content:center;
    border-bottom:2px solid red;
}
.item-card-terminal{
    width: 93px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ffb3b3;
    margin: 3px;
    border-radius: 5px;
    background-color: white;
    box-shadow: -2px 0 10px rgba(163, 163, 163, 0.3);
    height: fit-content;
    padding: 5px;
}
.search-container-items{
    width:95%;
}
.category-boxes-terminal {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    white-space: nowrap;
    padding: 10px 0;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.category-boxes-terminal::-webkit-scrollbar {
    height: 5px 
  }
.category-boxes-terminal::-webkit-scrollbar {
    height: 7px; 
  }
.category-boxes-terminal::-webkit-scrollbar-thumb {
    background-color: #c84347;
    border-radius: 5px; 
  }
.category-boxes-terminal::-webkit-scrollbar-track {
    background-color: #f0f0f0; 
  }
.category-box-item {
    flex-shrink: 0;
    background-color: #ffffff;
    color: #333;
    border: 1px solid #ffb3b3;
    border-radius: 12px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    min-width: 100px;
    height: 60px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width:180px
}
.item-title-terminal{
    font-weight:400;
    font-size:20px;
    text-align:center
}

/* -----------------------------------Actions----------------------------------------- */

.main-actions-box {
    width: 23vw;
    border: 1px solid red;
    margin: 5px;
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.main-Actions {
    height: 50vh;
    overflow-y: scroll;
    width: 20vw;
    padding-bottom: 5px;
    padding: 8px;
}
.main-Actions::-webkit-scrollbar {
    display:none
    }
.item-details{
    border:1px solid red;
    padding:5px;
    display:flex;
    justify-content:space-between;
}
.quantity-input {
    width: 30%;
    background-color:transparent;
    border:0.5px solid red;
    text-align:center;
    padding-left:8px;
}
.keypad-actions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    max-width: 400px;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 8px;

}
.keypad-container {
    width: 100%;
    display: flex;
    border-top: 1px solid silver
}
.keypad-button-actions {
    padding: 8px;
    font-size: 18px;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    background-color: #f2f2f2;
    color: #333;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c84347;
}
.keypad-button-actions:hover {
    background-color: #f4f4f4;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}
.keypad-button-actions:active {
    background-color: #e1e1e1;
    box-shadow: none;
 }
.keypad-button-actions.backspace {
    background-color: #f56262;
    color: white;
    font-weight: bold;
}
.confirm {
    padding: 8px;
}
.keypad-button-actions.confirm {
    background-color: #4caf50;
    color: white;
    font-weight: bold;
    padding: 8px;
}
.keypad-button-actions.confirm:hover {
    background-color: #45a049;
}
.cancel-actions {
    background-color: #c84347;
    width: 100%;
    color:white;
    height:30px;
    border:1px solid white;
    margin-top:10px
}
.actions-btns {
    width: 98%;
    max-height: 50px;
    display: flex;
    font-size: 15px;
    align-items: center;
    padding: 0px;
    margin-top: 3px;
    padding-top:10px;
    padding-bottom:10px;
    border:1px solid red
}
.actions-btn-icon {
    padding-left: 8px;
    padding-right: 8px;
    background-color: #eaeaea;
    height: 100%;
    align-items: center;
    display: flex;
    border-right: 1px solid black
}
.actions-btn-text {
    text-align: center;
    width: 100%;
    font-size:20px
}
.keypad-container-actions {
    padding: 8px;
}

/* -----------------------------------Add Items----------------------------------------- */
.main-actions-tables {
    width: 45vw;
    border: 1px solid red;
    margin: 5px;
    height: 85vh;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.table-section {
    height: 47vh;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.table-section::-webkit-scrollbar {
        display: none;
}
.terminal-table {
    height: fit-content;
    border: 1px solid red;
    width:100%;
}
.terminal-thead th, td {
    font-size:20px;
    border: 1px solid red;
}
.row-red {
    background-color: #f443361f;
}
.row.selected {
    background-color: #c84347;
    font-weight: bold;
    color: white
}
.row-red.selected {
    background-color: #c84347;
    color: white
}
.change-color-main{
    color:black;
    text-align:start;
    display:flex;
    font-weight:bold;
    text-align:center;
    margin-bottom:10px;
}
.change-color-label {
    color: black;
    text-align: start
}
.popup-btn-confirm{
    margin-top:20px;
    width:50%;
    height:30px;
    border:1px solid darkred;
}
.popup-btn-cancel {
    margin-top: 20px;
    width: 50%;
    height: 30px;
    border: 1px solid darkred;
}
.popup-content-items{
    background-color:white;
    border:4px solid darkred;
    padding:40px;
    border-radius:10px
}

/* -----------------------------------Show Item----------------------------------------- */

.payment-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f9f9f9;
    border-top: 2px solid #ccc;
    padding: 16px;
}
.payment-total {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}
.total-label {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}
.total-amount {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    margin: 8px 0;
    display:flex;
    justify-content:space-between
}
.open-amount {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    color: #555;
}
.amount {
    font-weight: bold;
    color: #000;
}
.amount-2{
    color:green
}
.payment-buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 16px;
}
.action-options {
    display: flex;
    gap: 8px;
}
.action-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px 16px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    width: 120px;
}
.action-btn:hover {
        background-color: #0056b3;
    }
.action-btn.exact {
        background-color: #28a745;
    }
.action-btn.exact:hover {
            background-color: #218838;
        }
.action-btn.card {
        background-color: #ffc107;
        color: #333;
    }
.action-btn.card:hover {
            background-color: #e0a800;
        }
.action-btn.custom {
        background-color: #17a2b8;
    }
.action-btn.custom:hover {
            background-color: #138496;
        }
.total-2{
    display:flex;
    justify-content:space-between
}
.context-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: 200px;
}
.context-menu ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
.context-menu ul li {
            padding: 10px;
            cursor: pointer;
        }
.context-menu ul li:hover {
                background-color: #f1f1f1;
            }
.quantity-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.quantity-controls button {
        padding: 5px 10px;
        font-size: 16px;
    }
.quantity-controls input {
        width: 50px;
        text-align: center;
        font-size: 16px;
    }
.context-menu-2 {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: fit-content;
}
.updating{
    padding:10px;
    display:flex;
    flex-direction:column;
    gap:5px
}
.updating div{
    display:flex;
    justify-content:space-between
}
.dec-btn{
    background-color:red;
    color:white;
    border:none;
}
.inc-btn {
    background-color: green;
    color: white;
    border: none;
}
.amount-btns-main{
    display:flex;
    gap:10px;
    justify-content:end
}
.amount-btns {
    padding: 20px;
    background-color: #c84347;
    color: white;
    border-radius: 5px;
    border: none;
    font-size: 18px
}
.coupon-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.coupon-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
}
.coupon-popup-content input {
        width:95%
    }
.coupon-popup-content input::placeholder{
    color:black;
}
.coupon-input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
}
.apply-coupon-btn {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    margin-right:10px
}
.apply-coupon-btn:hover {
        background-color: #45a049;
    }
.close-popup-btn {
    padding: 10px;
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;
}
.close-popup-btn:hover {
        background-color: #d32f2f;
    }

/* -----------------------------------Payment Css----------------------------------------- */

.payment-popup-overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.466);
    padding:20px
}
.payment-popup-content-items {
  height: fit-content;
    width: 50vw;
    background-color: white;
    border: 3px solid #c84347;
    border-radius: 10px;
    animation: animate2 0.5s ease-in;
    overflow: hidden;
    padding:10px
}
@keyframes animate2 {
    0% {
        margin-right: 1000px;
        opacity: 0;
    }

    100% {
        margin-right: 0px;
        opacity: 1;
    }
}
.sec-cash-summary {
    margin-bottom: 1rem;
}
.sec-cash-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}
.sec-cash-input {
    color: #1D4ED8; /* Blue */
    font-size: 1.25rem;
}
.sec-cash-change {
    color: #16A34A; /* Green */
}
.sec-cash-remaining {
    padding-top: 0.5rem;
    border-top: 1px solid #ccc;
}
.sec-cash-remaining-amount {
    color: #EF4444; /* Red */
}
.sec-number-pad {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
}
.sec-number-button {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 1.25rem;
    transition: background-color 0.2s ease;
    border:none
}
.sec-number-button:hover {
        background-color: #f3f4f6;
    }
.sec-number-button:active {
        background-color: #e5e7eb;

}
.sec-action-buttons {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.sec-backspace-button {
    background-color: #F87171; /* Red */
    padding: 1rem;
    border-radius: 0.5rem;
    color: white;
    font-size: 1.25rem;
    transition: background-color 0.2s ease;
    border:none
}
.sec-backspace-button:hover {
        background-color: #EF4444; /* Red */
    }
.sec-confirm-button {
    background-color: #34D399; /* Green */
    padding: 1rem;
    border-radius: 0.5rem;
    color: white;
    font-size: 1.25rem;
    transition: background-color 0.2s ease;
    width:130px;
    border:none
}
.sec-confirm-button:hover {
        background-color: #10B981; /* Green */
    }
.sec-payment-container {
    width: 95%;
    margin: 0 auto;
    background-color: #F3F4F6; /* Gray */
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.sec-cancel-button {
    margin-top: 1rem;
    width: 100%;
    background-color: #EF4444; /* Red */
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: background-color 0.2s ease;
}
.sec-cancel-button:hover {
        background-color: #DC2626; /* Red */
    }
.sec-tabs {
    display: flex;
    margin-bottom: 1rem;
}
.sec-tab {
    flex: 1;
    padding: 1rem;
    background-color: #e5e7eb;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s;
}
.sec-tab:hover {
        color: black;
        border-bottom:4px solid  black;
    }
.sec-tab.active {
        color: black;
        border-bottom: 4px solid black;
    }
.sec-card-selection {
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.sec-card-options {
    display: flex;
    gap: 1rem;
}
.sec-card-option {
    width:150px;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
    text-align:center
}
.sec-card-option:hover {
        background-color: #f3f4f6;
    }
.sec-card-option.selected {
        background-color: #34D399; /* Green */
        color: white;
    }
.sec-payment-btn {
    margin-top: 1rem;
}
.sec-pay-button {
    padding: 1rem 2rem;
    background-color: #34D399; /* Green */
    color: white;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
}
.sec-pay-button:hover {
        background-color: #10B981; /* Darker Green */
    }
.sec-cancel-button {
    margin-top: 1rem;
    width: 98%;
    background-color: #EF4444; /* Red */
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: background-color 0.2s ease;
    border:none
}
.sec-cancel-button:hover {
        background-color: #DC2626; /* Dark Red */
    }


/* -----------------------------------User side Css----------------------------------------- */


.pos-container {
    display: flex;
    gap: 24px;
    padding: 24px;
    max-width: 100vw;
    margin: 0 auto;
    background-color: #f9fafb;
    height:90vh
}
.pos-left-panel {
    flex-grow: 1;
}
.pos-card {
    background-color: white;
    padding: 16px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

}
.pos-right-panel{
    width:25%
}
.pos-header {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
}
.pos-search {
    position: relative;
    flex-grow: 1;
}
.pos-input {
    padding-left: 12px;
    padding-right: 32px;
    width: 94%;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    font-size: 14px;
    height: 36px;
}
.pos-input::placeholder {
    color:gray
}
.pos-search-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    color: #9ca3af;
}
.pos-btn {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    transition: background-color 0.3s;
}
.pos-cancel {
    background-color: #f87171;
    color: white;
    border: none;
}
.pos-cancel:hover {
        background-color: #ef4444;
    }
.pos-pause {
    background-color: #fbbf24;
    color: white;
    border: none;
}
.pos-pause:hover {
        background-color: #f59e0b;
    }
.pos-table-container {
    background-color: #ffe5e5;
    border-radius: 8px;
    overflow-x: auto;
}
.pos-table {
    width: 100%;
    border-collapse: collapse;
}
.pos-table-header
.pos-table th, .pos-table td {
    padding: 12px;
    border-top: 1px solid #d1fae5;
}
.pos-table-row {
    transition: background-color 0.3s;
}
.pos-table-row:hover {
        background-color: #c84347;
        color:white
    }
.pos-stock {
    font-size: 12px;
    color: #6b7280;
}
.pos-discount {
    background-color: #c84347;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 500;
}
.pos-ghost {
    background-color: transparent;
    border: none;
}
.pos-icon {
    width: 16px;
    height: 16px;
    color: #9ca3af;
}
.pos-customer {
    width:34%;
    margin-top: 24px;
}
.pos-payment {
    margin-top: 24px;
}
.pos-totals {
    margin-bottom: 24px;
}
.pos-total-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}
.pos-total-line span {
        font-size: 14px;
    }
.pos-total-bold {
    font-weight: 600;
}
.pos-discount-amount {
    color: #ef4444;
}
.pos-paid-due {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    border-top: 1px solid #d1fae5;
}
.pos-label {
    color: #6b7280;
    font-size: 14px;
}
.pos-amount {
    font-size: 20px;
    font-weight: 600;
}
.pos-due {
    color: #ef4444;
}
.pos-payment-methods {
    margin-bottom: 24px;
}
.pos-methods-header {
    color: #6b7280;
    font-size: 14px;
}
.pos-method-item {
    display: flex;
    justify-content: space-between;
    background-color: #ffe5e5;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
}
.pos-end-sale {
    width: 100%;
    background-color: #c84347;
    color: white;
    padding: 12px 16px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    transition: background-color 0.3s;
}
.pos-end-sale:hover {
        background-color: #059669;
    }

/* -----------------------------------Parent component----------------------------------------- */


.discount-card {
    width: 70%; 
    height: 73vh;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px; 
    box-sizing: border-box; 
    font-size: 14px; 
}
.discount-card-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1; /* Allow the content to fill available space */
    justify-content: flex-start; /* Align elements at the top */
    align-items: stretch;
    overflow: hidden;
}
.discount-form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.discount-label {
    font-size: 1rem;
    color: #555;
}
.discount-input {
    font-size: 1.2rem; /* Slightly bigger font for better readability */
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    text-align: right;
    box-sizing: border-box;
}
.discount-tabs {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.discount-tab-button {
    flex: 1;
    padding: 7px;
    text-align: center;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.discount-tab-button.discount-tab-active {
        background-color: #007bff;
        color: white;
    }
.discount-tab-button:hover {
        background-color: #e0e0e0;
    }
.discount-select {
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}
.discount-numpad {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 equally spaced columns */
    gap: 8px;
    margin-top: 10px;
}
.discount-numpad-button {
    font-size: 1.2rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f7f7f7;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 10px
}
.discount-numpad-button:hover {
        background-color: #e0e0e0;
    }
.discount-numpad-backspace {
    background-color: #ffcccc;
}
.discount-numpad-backspace:hover {
        background-color: #ff9999;
    }
.discount-numpad-enter {
    background-color: #28a745;
    color: white;
}
.discount-numpad-enter:hover {
        background-color: #218838;
    }
.discount-cancel-button {
    padding: 12px;
    font-size: 1rem;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
}
.discount-cancel-button:hover {
        background-color: #c82333;
    }
@media (max-width: 768px) {
    .discount-card {
        width: 85vw; /* Increase width on smaller screens */
        height: 70vh; /* Adjust height to ensure it's usable */
        padding: 10px;
    }

    .discount-input, .discount-select, .discount-numpad-button {
        font-size: 1rem; /* Reduce font size for smaller screens */
        padding: 8px; /* Reduce padding to fit smaller screens */
    }

    .discount-tab-button {
        font-size: 0.9rem;
        padding: 8px;
    }
}
@media (max-width: 480px) {
    .discount-card {
        width: 90vw; /* Further increase width on very small screens */
        height: 60vh; /* Adjust height */
    }

    .discount-input, .discount-select, .discount-numpad-button {
        font-size: 0.9rem; /* Reduce font size */
        padding: 6px; /* Further reduce padding */
    }

    .discount-numpad {
        grid-template-columns: repeat(3, 1fr); /* Reduce number of columns for small screens */
    }

    .discount-tab-button {
        font-size: 0.8rem;
        padding: 6px;
    }
}
