.h-tax-items-container {
  padding: 2rem;
  max-width: 100%;
  overflow-x: auto;
  background-color: #fff;
}

/* Table Styles */
.h-tax-items-table {
  width: 100%;
  border-collapse: collapse;
  
  white-space: nowrap;
}

.h-tax-items-table th {
  background-color: #c84347; /* Theme color for table headers */
  color: #fff; /* White text for contrast */
  font-weight: 500;
  text-align: left;
  padding: 12px 15px;
  border: 2px solid #c84347; /* Thicker borders for a refined look */
}

.h-tax-items-table td {
  padding: 10px 15px;
  border: 1px solid #ddd;
  color: #333;
}

.h-tax-items-table tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Light gray for alternating rows */
}

.h-tax-items-table tbody tr:hover {
  background-color: #f1f1f1; /* Subtle highlight on hover */
  border: 1px solid #c84347; /* Add theme border on hover */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .h-tax-items-container {
    padding: 1rem;
  }
  
  h1 {
    font-size: 1.25rem;
  }
  
  .h-tax-items-table th,
  .h-tax-items-table td {
    padding: 8px 10px;
    font-size: 0.9rem;
  }
}
