.h-additional-fields-container {
  padding: 2rem;
  max-width: 100%;
  overflow-x: auto;
}

.h-additional-fields-table {
  width: 100%;
  border-collapse: collapse;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.h-additional-fields-table th {
  background-color: #c84347; /* Apply theme color */
  color: #fff;
  font-weight: 500;
  text-align: left;
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.h-additional-fields-table td {
  padding: 10px 15px;
  border: 1px solid #ddd;
  color: #333;
}

.h-additional-fields-table tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

.h-additional-fields-table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .h-additional-fields-container {
    padding: 1rem;
  }

  .h-additional-fields-table th,
  .h-additional-fields-table td {
    padding: 8px 10px;
    font-size: 0.9rem; /* Corrected from 0.h-9rem */
  }
}
