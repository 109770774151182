.drawers-details-page {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .drawers-details-page .general-data-section,
  .administrative-data-section {
    margin-bottom: 20px;
  }
  
  .drawers-details-page .general-data-section h2,
  .drawers-details-page .administrative-data-section h2 {
    font-size: 20px;
    color: #c84347;
    margin-bottom: 10px;
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
  }
  
  .drawers-details-page .general-data-fields p,
  .drawers-details-page .administrative-data-fields p {
    font-size: 16px;
    margin: 8px 0;
  }
  
  .drawers-details-page .general-data-fields strong,
  .drawers-details-page .administrative-data-fields strong {
    color: #555;
  }
  
  .drawers-details-page .general-data-fields,
  .drawers-details-page .administrative-data-fields {
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  